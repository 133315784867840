var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"main"}},[_c('mv-full-page',{attrs:{"isPointer":false,"isV":_vm.isV,"pages":_vm.pageCount,"page":_vm.currentPage,"isCache":true,"height":"calc(100% - 0.72rem)","transition":{
      duration: '500ms', // 动画时长
      timingFun: 'ease', // 动画速度曲线
      delay: '0s', // 动画延迟
    },"config":{
      disabled: this.localValue
    }},on:{"update:page":function($event){_vm.currentPage=$event},"rollEnd":_vm.onRollEnd},scopedSlots:_vm._u([{key:"page1",fn:function(){return [_c('div',{staticClass:"video"},[_c('div',{staticClass:"video-container"},[_c('video',{ref:"myVideo",staticClass:"video",attrs:{"src":_vm.videoPath,"muted":"","autoplay":"","loop":"","controls":"","playsinline":"","preload":"auto","x5-video-player-type":"h5","webkit-playsinline":"true","x5-playsinline":"true","poster":_vm.videoPath + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast'},domProps:{"muted":true},on:{"contextmenu":function($event){$event.preventDefault();}}})]),_c('div',{staticClass:"copyright"},[_vm._v("广告审查批准文号:苏械广审(视)第260322-05152号 盆底康复治疗仪 医疗器械注册号:苏械注准20242090347"),_c('br'),_vm._v("请仔细阅读产品说明书或者在医务人员的指导下购买和使用 禁忌内容或者注意事项详见说明书 南京麦豆健康科技有限公司")])])]},proxy:true},{key:"page2",fn:function(){return [_c('div',{staticClass:"disease"},[_c('div',{staticClass:"left"},[_c('p',[_vm._v("澜渟 T20 series")]),_c('p',[_vm._v("治疗盆底症状＋阴道松弛")]),_c('img',{attrs:{"alt":"","src":require('@/assets/img/product/t20/diease-left.png')}})]),_c('img',{staticClass:"right",attrs:{"alt":"","src":require('@/assets/img/product/t20/diease-right.png')}})])]},proxy:true},{key:"page3",fn:function(){return [_c('div',{staticClass:"system"},[_c('p',[_vm._v("双重修复 紧致系统")]),_c('full-swiper',{attrs:{"datas":_vm.systemList}})],1)]},proxy:true},{key:"page4",fn:function(){return [_c('div',{staticClass:"pattern"},[_c('p',{staticClass:"title"},[_vm._v("创新训练模式")]),_c('video-swiper',{attrs:{"animation-interval":1000,"interval":4100,"datas":_vm.patternList}})],1)]},proxy:true},{key:"page5",fn:function(){return [_c('div',{staticClass:"tech"},[_c('video',{directives:[{name:"animate",rawName:"v-animate",value:({
           name: 'zoomIn',
        }),expression:"{\n           name: 'zoomIn',\n        }"}],attrs:{"src":"//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20-4.mp4","muted":"","autoplay":"","loop":"","playsinline":"true","preload":"auto","x5-video-player-type":"h5","webkit-playsinline":"true","x5-playsinline":"true"},domProps:{"muted":true},on:{"contextmenu":function($event){$event.preventDefault();}}})])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }