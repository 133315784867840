<template>
  <div id="main">
    <mv-full-page
      :isPointer="false"
      :isV="isV"
      :pages="pageCount"
      :page.sync="currentPage"
      :isCache="true"
      height="calc(100% - 0.72rem)"
      :transition="{
        duration: '500ms', // 动画时长
        timingFun: 'ease', // 动画速度曲线
        delay: '0s', // 动画延迟
      }"
      @rollEnd="onRollEnd"
      :config="{
        disabled: this.localValue
      }"
    >
      <template #page1>
        <div class="video">
          <div class="video-container">
            <video class="video" ref="myVideo" :src="videoPath" muted autoplay loop
                   @contextmenu.prevent
                   controls playsinline preload="auto" x5-video-player-type="h5" webkit-playsinline="true" x5-playsinline="true"
                   :poster="videoPath + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast'">
            </video>
          </div>
          <div class="copyright">广告审查批准文号:苏械广审(视)第260322-05152号  盆底康复治疗仪  医疗器械注册号:苏械注准20242090347<br/>请仔细阅读产品说明书或者在医务人员的指导下购买和使用  禁忌内容或者注意事项详见说明书  南京麦豆健康科技有限公司</div>
        </div>
      </template>
      <template #page2>
        <div class="disease">
          <div class="left">
            <p>澜渟 T20 series</p>
            <p>治疗盆底症状＋阴道松弛</p>
            <img alt :src="require('@/assets/img/product/t20/diease-left.png')"/>
          </div>
          <img class="right" alt :src="require('@/assets/img/product/t20/diease-right.png')"/>
        </div>
      </template>
      <template #page3>
        <div class="system">
          <p>双重修复 紧致系统</p>
          <full-swiper :datas="systemList"></full-swiper>
        </div>
      </template>
      <template #page4>
        <div class="pattern">
          <p class="title">创新训练模式</p>
          <video-swiper :animation-interval="1000" :interval="4100" :datas="patternList"></video-swiper>
        </div>
      </template>
      <template #page5>
        <div class="tech">
          <video v-animate="{
             name: 'zoomIn',
          }"
            src="//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20-4.mp4" muted autoplay loop
                 @contextmenu.prevent
                 playsinline="true" preload="auto" x5-video-player-type="h5" webkit-playsinline="true" x5-playsinline="true"
          ></video>
        </div>
      </template>
    </mv-full-page>
  </div>
</template>

<script>
import '@/assets/css/animate.min.css';
import 'mv-full-page/lib-dist/mv-full-page.css';
import MvFullPage from 'mv-full-page';
import Vue from 'vue';
import { FullSwiper } from '@/components/full-swiper';
import { VideoSwiper } from '@/components/video-swiper';
import directives from '@/utils/directives';

Vue.use(directives);
Vue.use(MvFullPage);

export default {
  data() {
    return {
      localValue: this.value,
      isV: true,
      currentPage: 1,
      pageCount: 5,
      videoPath: `//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20.mp4`,
      systemList: [
        {
          path: require('@/assets/img/product/t20/system-1.png'),
          title: '盆底系统',
          desc: '唤活盆底 改善松弛',
        },
        {
          path: require('@/assets/img/product/t20/system-2.png'),
          title: '私密系统',
          desc: '增强收缩 紧致阴道',
        },
      ],
      patternList: [
        {
          path: '//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20-1.mp4',
          title: '科学双向报告，还原大设备报告',
          desc: '进阶版FSFI量表+盆底6分17秒Glazer评估',
        },
        {
          path: '//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20-2.mp4',
          title: '无线腹肌监测',
          desc: '提升训练有效性',
        },
        {
          path: '//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20-3.mp4',
          title: '双重护航',
          desc: '建立个人专属训练模式',
        },
        {
          path: '//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20-1.mp4',
          title: '科学双向报告，还原大设备报告',
          desc: '进阶版FSFI量表+盆底6分17秒Glazer评估',
        },
        {
          path: '//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20-2.mp4',
          title: '无线腹肌监测',
          desc: '提升训练有效性',
        },
        {
          path: '//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20-3.mp4',
          title: '双重护航',
          desc: '建立个人专属训练模式',
        },
      ],
    };
  },
  props: {
    value: { //数据源（必填）
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$parent.$emit('rollInit');
  },
  methods: {
    onRollEnd(page) {
      this.$parent.$emit('rollPage', page);
      if (page === this.pageCount) {
        this.$parent.$emit('rollEnd');
      } else {
        this.$parent.$emit('rollTop');
      }
    },
  },
  watch: {
    value(val) {
      this.localValue = val;
    },
    currentPage(val) {
      if (val === 1) {
        this.localValue = false;
      }
    },
  },
  components: {
    FullSwiper,
    VideoSwiper,
  },
};
</script>

<style lang="scss" scoped>
.full-page-wrapper {
  top: 0.72rem;

  // 强制第一页全屏
  /deep/ .all-page .page:nth-child(1) {
    height: calc(100% / 5 + 0.72rem) !important;
    margin-top: -0.72rem !important;
  }
}

.video {
  position: relative;
  width: 100%;
  height: 100%;

  > .video-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    > .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  >.copyright {
    position: absolute;
    bottom: 0.24rem;
    left: 0;
    width: 100%;
    font-weight: 400;
    font-size: 0.12rem;
    color: #FFFFFF;
    line-height: 0.17rem;
    text-align: center;
    pointer-events: none;
  }
}

.disease {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #DEE1F7 0%, #AEB4F2 100%);
  padding: 1.96rem 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > p:nth-child(1) {
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
    }

    > p:nth-child(2) {
      margin-top: 0.12rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.39rem;
    }

    > img {
      margin-top: 0.6rem;
      width: 7.66rem;
    }
  }

  > .right {
    width: 8.25rem;
  }
}

.system {
  position: relative;
  width: 100%;
  height: 100%;
  background: #FAFBFE;
  padding: 0 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > p {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.56rem;
    color: #000000;
    line-height: 0.78rem;
  }

  > .swiper {
    border-radius: 0.24rem;
    width: 100%;
    height: 7.62rem;
  }
}


.pattern {
  width: 100%;
  height: 100%;
  position: relative;
  background: #FAFBFE;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > .title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.56rem;
    color: #000000;
    line-height: 0.78rem;
  }

  > .swiper {
    height: 6.39rem;
    margin-bottom: 1.2rem;
  }
}

.tech {
  width: 100%;
  height: 100%;
  position: relative;
  background: #FAFBFE;
  padding: 0.2rem 0.9rem;

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.24rem;
  }
}
</style>
