<template>
  <div class="swiper">
    <div class="swiper-item" v-for="(item, i) in datas" :key="i" :class="getImgClass(i)"
         :ref='"img"+i'>
      <video :src="item.path" muted autoplay loop preload="auto"
             @contextmenu.prevent
             playsinline="true" x5-video-player-type="h5" webkit-playsinline="true" x5-playsinline="true"/>
      <p class="title">{{ item.title }}</p>
      <p class="desc">{{ item.desc }}</p>
    </div>
    <div class="swiper-prev" @click.stop="transPrev">
    </div>
    <div class="swiper-next" @click.stop="transNext">
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoSwiper',
  props: {
    datas: { //数据源（必填）
      type: Array,
      default: () => {
        return [];
      },
    },
    interval: { //轮播间隔（单位：ms）
      type: Number,
      default: () => {
        return 3000;
      },
    },
    animationInterval: { //轮播执行动画间隔（单位：ms）
      type: Number,
      default: () => {
        return 400;
      },
    },
    moveDistance: { //需触发轮播的鼠标移动x轴最小距离（单位：px）
      type: Number,
      default: () => {
        return 100;
      },
    },
  },
  data() {
    return {
      oldCurrentIndex: -1,
      currIndex: 0,
      transing: false,
      rem: 100,
    };
  },
  mounted() {
    this.rem = document.documentElement.style.fontSize.replace('px', '') * 1;
    this.resetItemPosition();
    this.refreshInterval();
  },
  unmounted() {
    clearInterval(this.intervalFun);
  },
  watch: {
    currIndex(newVal) {
      if (this.transing) {
        return;
      }
      this.transing = true;
      this.resetItemPosition();
      setTimeout(() => {
        this.transClear();
      }, this.animationInterval);

      this.datas.forEach((item, index) => {
        const img = this.$refs[`img${index}`][0];
        var video = img.getElementsByTagName('video')[0];
        video.currentTime = 0;
        video.pause();
        if (index == newVal) {
          video.play();
        }
      });
    },
  },
  methods: {
    resetItemPosition() {
      this.datas.forEach((item, index) => {
        let translate = this.translateItem(index, this.currIndex);
        const img = this.$refs[`img${index}`][0];
        img.style.zIndex = this.getZIndex(index);
        img.style.transition = `all ${this.animationInterval / 1000}s`;
        img.style.transform = 'translateX(' + translate + 'px)';
      });
    },

    getZIndex(i) {
      if (i == this.currIndex) {
        return this.datas.length
      }
      if (this.currIndex == 0 && i == this.datas.length - 1) {
        // 第一个 要处理一下最后一个
        if (this.currIndex > this.oldCurrentIndex) {
          return 1
        }
        return 2
      } else if (this.currIndex == this.datas.length - 1 && i == 0) {
        // 最后一个 要处理一下第一个
        return 2
      }
      let isInStage = Math.round(Math.abs(i - this.currIndex)) <= 1;
      if (isInStage) {
        return 2
      }
      return 1
    },

    translateItem(index, currentIndex) {
      let isInStage = Math.round(Math.abs(index - currentIndex)) <= 1;
      return this.calcCardTranslate(isInStage, index, currentIndex);
    },

    calcCardTranslate(indexInStage, index, activeIndex) {
      const parentWidth = this.$parent.$el.offsetWidth;
      if (activeIndex == 0 && index == this.datas.length - 1) {
        // 第一个 要处理一下最后一个
        return -7.74 * this.rem;
      } else if (activeIndex == this.datas.length - 1 && index == 0) {
        // 最后一个 要处理一下第一个
        return 15.54 * this.rem;
      }
      if (indexInStage) {
        return (index - activeIndex) * (11.4 + 0.24) * this.rem + 3.94 * this.rem;
      }  else if (index < activeIndex) {
        return - 11.4 * this.rem;
      } else {
        return parentWidth;
      }
    },
    /**
     刷新定时器
     @param
     @return
     */
    refreshInterval() {
      clearInterval(this.intervalFun);
      this.intervalFun = setInterval(() => {
        if (this.$refs[`img${this.currIndex}`].length > 0) {
          this.transNext();
        }
      }, this.interval);
    },
    transPrev() {
      this.currIndex = this.getPrevIndex(this.currIndex);
      this.refreshInterval();
    },
    transNext() {
      this.currIndex = this.getNextIndex(this.currIndex);
      this.refreshInterval();
    },
    /**
     轮播滑动清空
     @param
     @return
     */
    transClear() {
      this.transing = false;
    },
    /**
     获取上一个序号
     @param {Number} i 序号
     @return {Number} 上一个序号
     */
    getPrevIndex(i) {
      if (i == 0) {
        return this.datas.length - 1;
      }
      return i - 1;
    },
    /**
     获取下一个序号
     @param {Number} i 序号
     @return {Number} 下一个序号
     */
    getNextIndex(i) {
      if (i == this.datas.length - 1) {
        return 0;
      }
      return i + 1;
    },
    /**
     获取图片class
     @param {Number} i 序号
     @return {String} 图片class
     */
    getImgClass(i) {
      if (i == this.currIndex) {
        return 'curr';
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.swiper {
  position: relative;
  height: 100%;
  overflow: hidden;

  > .swiper-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 11.4rem;
    height: 6.39rem;
    overflow: hidden;

    > video {
      width: 100%;
      height: 100%;
      border-radius: .24rem;
      transition: all 0.4s;
      object-fit: cover;
    }

    >.title {
      font-weight: 500;
      font-size: 0.28rem;
      color: #fff;
      line-height: 0.39rem;
      position: absolute;
      bottom: 1rem;
      width: 100%;
      text-align: center;
    }

    >.desc {
      font-size: 0.2rem;
      color: #fff;
      line-height: 0.28rem;
      position: absolute;
      bottom: 0.6rem;
      width: 100%;
      text-align: center;
    }
  }
}

.swiper-prev {
  position: absolute;
  top: 50%;
  left: 4.14rem;
  transform: translateY(-50%);
  width: 0.6rem;
  height: 0.6rem;
  background: url("../img/swiper-left.png") no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
  z-index: 99;

  &:hover {
    background: url("../img/swiper-left-hover.png") no-repeat center center;
    background-size: 100% 100%;
  }
}

.swiper-next {
  position: absolute;
  top: 50%;
  right: 4.14rem;
  transform: translateY(-50%);
  z-index: 99;
  width: 0.6rem;
  height: 0.6rem;
  background: url("../img/swiper-right.png") no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;

  &:hover {
    background: url("../img/swiper-right-hover.png") no-repeat center center;
    background-size: 100% 100%;
  }
}
</style>
